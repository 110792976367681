@import "modules/responsive-type.scss";
@import url('https://fonts.googleapis.com/css?family=Advent+Pro|Hammersmith+One');
$primary: #5f301b;

/* MAIN COLOR */

$secondary: #d6792a;

/* SECONDARY COLOR */

$blk: #333;

/* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */

$wht: #fafafa;
$border-radius: 0px;

/* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */

$footerLinks: #fafafa;
// Set your maximum and minimum screen sizes.
$min_width: 320px;
$max_width: 1920px;
// Font sizes will range between the $min_font and $max_font.
$min_font: 15px;
$max_font: 22px;
// Change these values to change the "scale" between different headers
// (h1,h2,h3, etc.). Larger numbers = larger font-sizes. 
$mod_1: 1.2; // mobile
$mod_2: 1.4; // desktop
html,
body {
    height: 100%;
}

html {
    @include fluid-type($min_width,
    $max_width,
    $min_font,
    $max_font);
}

p,
ol,
ul {
    font-size: 1rem;
    font-family: 'Advent Pro', sans-serif;
}

a {
    font-family: 'Hammersmith One', sans-serif;
    font-size: 1rem;
}

h1 {
    font-size: $mod_1*$mod_1*$mod_1*$mod_1 *1rem;
    @include fluid-type($min_width,
    $max_width,
    $mod_1*$mod_1*$mod_1 *$min_font,
    $mod_2*$mod_2*$mod_2 *$min_font);
    font-family: 'Hammersmith One', sans-serif;
}

h2 {
    font-size: $mod_1*$mod_1*$mod_1 *1rem;
    @include fluid-type($min_width,
    $max_width,
    $mod_1*$mod_1*$mod_1 *$min_font,
    $mod_2*$mod_2*$mod_2 *$min_font);
    font-family: 'Hammersmith One', sans-serif;
}

h3 {
    font-size: $mod_1*$mod_1 *1rem;
    @include fluid-type($min_width,
    $max_width,
    $mod_1*$mod_1 *$min_font,
    $mod_2*$mod_2 *$min_font);
    font-family: 'Hammersmith One', sans-serif;
}

body {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
}

.flash {
    display: none;
}

q,
blockquote {
    quotes: "“" "”";
}

.navbarFixed {
    background: rgb(255, 255, 255);
    background: -moz-linear-gradient(left, rgba(255, 255, 255, 1) 0%, rgba(234, 162, 10, 1) 100%);
    background: -webkit-linear-gradient(left, rgba(255, 255, 255, 1) 0%, rgba(234, 162, 10, 1) 100%);
    background: linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(234, 162, 10, 1) 100%);
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 999;
}


/* Change autocomplete styles in WebKit */

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    border: 1px solid #0065dd;
    -webkit-text-fill-color: #0065dd;
    -webkit-box-shadow: 0 0 0px 1000px #000 inset;
    transition: background-color 5000s ease-in-out 0s;
}

nav.navbar {
    z-index: 1000;
    border: none;
    border-radius: $border-radius;
    .navbar-nav {
        >li {
            text-align: center !important;
        }
        >li>a {
            text-align: center;
            margin-top: 5px;
            display: flex;
            align-items: center;
            color: $primary;
            @media (max-width: 767px) {
                margin-top: 0;
                padding: 6px 0px;
                display: inline-block;
                text-align: center !important;
            }
            &:focus,
            &:active {
                background: transparent;
                color: $primary;
                outline: 0;
            }
            &:hover {
                background: transparent;
                text-decoration: underline;
                color: $secondary;
                @media (max-width: 1024px) {
                    background: transparent;
                    color: $blk;
                    outline: 0;
                }
            }
        }
    }
}

.navbar-right {
    margin-top: 0px;
}

.navbar-toggle {
    margin: 20px 15px 8px 0px;
    border: 1px solid transparent;
    border-radius: 4px;
    @media (max-width: 767px) and (orientation: landscape) {
        margin: 12px 15px 8px 0px;
    }
}

.navbar-nav>.active>a {
    background: transparent !important;
    color: $primary !important;
}


/** LOGIN FORM **/

@mixin btn {
    border: none;
    border-radius: $border-radius;
    background: $primary;
    color: $wht;
    padding: 0.5em 2em;
    font-size: 1.25em;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1px;
    display: inline-block;
    margin: 2em 0em;
    transition: 0.7s ease all;
    &:hover {
        background: lighten($primary, 10%);
        color: lighten($wht, 10%);
        text-decoration: none;
        @media (max-width: 1024px) {
            background: $secondary;
            color: $wht;
            box-shadow: 0px 0px 5px $blk;
        }
    }
}

.btn-default {
    @include btn;
}

.modal-dialog {
    max-width: 500px;
    width: 100%;
    text-align: center;
    margin: 6em auto;
    .close {
        display: none;
    }
    .modal-content {
        color: $primary;
        h2 {
            text-align: center;
            font-size: 25px;
        }
    }
    a.btn,
    a.btn-default,
    button {
        @include btn margin: 0;
        display: block;
    }
}

.modal-header,
.modal-footer {
    background: $primary;
    color: $wht;
}

.modal-open {
    overflow: auto;
    padding-right: 0px !important;
}


/** END LOGIN FORM **/

footer {
    padding: 50px 0px 20px;
    background: $primary;
    color: $footerLinks;
    font-size: 15px;
    a {
        color: $footerLinks;
        white-space: nowrap;
        font-size: 15px;
        @media (max-width: 990px) {
            font-size: 13px;
        }
        &:hover {
            color: lighten($footerLinks, 10%);
            outline: 0 !important;
            text-decoration: none;
        }
        &:focus {
            color: $wht;
            outline: 0 !important;
            text-decoration: none;
        }
    }
    p {
        font-size: 15px;
        @media (max-width: 990px) {
            font-size: 13px;
        }
    }
}

input#username,
input#password {
    width: 100%;
    @media (max-width: 1024px) {
        font-size: 16px;
    }
}

select {
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
}

a,
a:hover,
a:focus,
a:active {
    outline: 0 !important;
}

.logo {
    max-width: 250px;
    padding: 0.5em;
}

@media(max-width: 767px) {
    .logo {
        max-width: 275px;
    }
    .navbar-header a {
        float: left;
    }
}

@media (max-width: 600px) {
    .logo {
        max-width: 225px;
    }
    .navbar-toggle {
        margin-top: 8px;
    }
}

.top-pad {
    padding: 3em;
    background: white;
    @media (max-width: 990px) {
        padding: 1em;
    }
}

.flex {
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 1024px) {
        display: block;
        /* may need to be inline-block */
    }
}

.terms,
.privacy {
    p {
        font-size: 1rem;
    }
    & ol {
        li {
            font-size: 1rem;
        }
        & ol li {
            font-size: 0.85rem;
        }
    }
}

.spacer-b {
    margin-bottom: 50px;
}


/* ============ TOP BAR =============== */

.top-bar {
    height: 23px;
    background: rgb(255, 255, 255);
    background: -moz-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(229, 229, 229, 1) 100%);
    background: -webkit-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(229, 229, 229, 1) 100%);
    background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(229, 229, 229, 1) 100%);
}

.top-bar p {
    font-size: 1.15em;
}

.top-bar a {
    font-size: 1.15em;
    margin-top: 15px;
    color: $primary;
}


/* =========== HEADER ============== */

.header {
    background: url('../img/banner.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    text-align: center;
}

.header-overlay {
    background: rgba(95, 48, 27, .65);
    padding: 250px 0px;
    @media (max-width: 767px) {
        padding: 50px 0px;
    }
}

.header h1 {
    color: $wht;
}

.header p {
    color: $wht;
}


/* =========== SECTION ONE ============== */

.section-one {
    padding: 50px 0px;
    text-align: center;
}

.section-one .border {
    border: 3px solid $secondary;
    padding: 25px;
    min-height: 494px;
    -webkit-box-shadow: 0px 0px 32px -6px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 32px -6px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 32px -6px rgba(0, 0, 0, 0.75);
    @media (max-width: 1024px) {
        min-height: 294px;
        margin-bottom: 25px;
    }
}

.section-one h1 {
    color: $primary;
}

.section-one h3 {
    color: $secondary;
}


/* =========== SECTION TWO ============== */

.section-two {
    background: url('../img/lower-banner.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    text-align: center;
}

.section-two-overlay {
    background: rgba(95, 48, 27, .65);
    padding: 250px 0px;
    @media (max-width: 767px) {
        padding: 50px 0px;
    }
}

.section-two h1 {
    color: $wht;
}

.section-two p {
    color: $wht;
}


/* =========== SECTION THREE ============== */

.section-three {
    padding: 50px 15px;
    @media (max-width: 1023px) {
        text-align: center;
        padding: 50px 0px;
    }
}

.section-three .border {
    border: 3px solid $secondary;
    padding: 25px;
    min-height: 320px;
    -webkit-box-shadow: 0px 0px 32px -6px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 32px -6px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 32px -6px rgba(0, 0, 0, 0.75);
    @media (max-width: 1199px) {
        min-height: 247px;
    }
}

.section-three h1 {
    margin-top: 0px;
    color: $primary;
    @media (max-width: 1023px) {
        margin-top: 25px;
    }
}

.section-three h3 {
    color: $secondary;
}

.section-three img {
    width: 100%;
    display: block;
    margin: 0px auto;
    max-width: 800px;
}


/* =========== SECTION FOUR ============== */

.section-four {
    background: url('../img/lower-banner-2.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    text-align: center;
}

.section-four-overlay {
    background: rgba(95, 48, 27, .65);
    padding: 250px 0px;
    @media (max-width: 767px) {
        padding: 50px 0px;
    }
}

.section-four h1 {
    color: $wht;
}

.section-four p {
    color: $wht;
}


/* =========== SECTION FIVE ============== */

.section-five {
    padding: 50px 0px;
}

.section-five h1 {
    color: $primary;
}

.section-five h3 {
    color: $secondary;
}